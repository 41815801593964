import { formatMuitlData } from 'public/src/pages/components/filter_new/common/format'
import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'
import { TAG_ITEM_TYPE, FILTER_BAR_MAP } from 'public/src/pages/components/FilterBar/utils/const.js'
import { getSourceInsideFilterOrCloudTagPanel } from './index.js'

// 是否自定义属性
export function getIsCustomAttr (attr, key = 'data') { 
  return attr?.[key]?.some(item => item[key]?.length > 0)
}

// 获取当前选中的属性
export function getActiveAttrList (attrs, queryParams, filterBar) {
  let { attr_ids, tsp_ids = '' } = queryParams
  const { isCccxFilterConfig } = filterBar?.filterConfig
  if (!isCccxFilterConfig && (!attr_ids || !attr_ids.split)) return []

  // 自定义属性，子级可能不包含父级id
  // const idsSet = new Set((attr_ids.split('-') || []).map(item => String(item.split('_')[0] || 0)))
  // if (!idsSet.size) return {}

  const activeAttrList = isCccxFilterConfig ? tsp_ids.split(',')?.map(id => id.split('_')[1]) : []
  
  attrs.forEach(attr => {
    if (/^-*$/g.test(attr_ids)) return

    // 将mix_value长度从大到小排序, 避免匹配到错误的值
    const isCustomAttr = getIsCustomAttr(attr)
    let list = []
    if (isCustomAttr) { 
      attr.data.forEach(item => {
        list = list.concat(item.data)
      })
    } else {
      list = [...(attr.data || [])].sort((a, b) => (b.mix_value.length - a.mix_value.length))
    }
      

    list.forEach(item => {
      const { mix_value } = item
      if (!(new RegExp(mix_value)).test(attr_ids)) return

      attr_ids = attr_ids.replace(mix_value, '')
      activeAttrList.push(mix_value)
    })
  })

  return activeAttrList
}

export function formatAttrList (attrs) {
  if (attrs && !attrs.length) return []

  // 只保留有数据的项
  attrs.forEach(attr => {
    if (attr.show_group) {
      attr.data = attr.groups
      attr.dataType = 'img'
      return
    } 
    attr.dataType = 'mulit'
    attr.data = attr.attr_values
    if (getIsCustomAttr(attr, 'attr_values')) { 
      attr.data = formatAttrList(attr.data)
      // 处理dataType
      attr.data.forEach(item => {
        item.data.forEach(obj => {
          if (obj.nodeType == 7) {
            obj.dataType = 'tsp'
          } else if (obj.attr_image) {
            obj.dataType = 'img'
            obj.label_img = obj.attr_image
          }
        })
      })
    }
  })

  return formatMuitlData({
    attr_id: 'id',
    attr_name: 'name',
    data: 'data',
    dataItem: {
      attr_id: 'id',
      attr_filter: 'mix_value',
      attr_value: 'label',
      group_name: 'label',
      group_image: 'label_img',
    }
  }, attrs)
}

// 去除属性类型的图文
function filterByPicTopNav (attrs, picTopNav = []) {
  if (picTopNav.length < 2) return attrs

  const attrIds = picTopNav.reduce((ids, item) => {
    item.tabType == 2 && ids.push(item.tabId)

    return ids
  }, [])

  return attrs.filter(item => !attrIds.includes(item.attr_id))
}

export function getAttrList ({ filterBar, picTopNav, queryParams, filterBarType }) {
  if (!filterBar?.filterData?.filterAttrs?.length) return []
  
  let list = filterByPicTopNav(filterBar?.filterData?.filterAttrs, picTopNav)
  list = formatAttrList(list)
  const activeAttrList  = getActiveAttrList(list, queryParams, filterBar)
  return list.map(attr => {
    const isCustomAttr = getIsCustomAttr(attr)
    const selectedIds = [] // 被选中的属性值个数
    const childList = isCustomAttr ? attr.data.reduce((acc, cur) => acc.concat(cur.data), []) : attr.data
    childList.forEach(item => {
      if (activeAttrList?.includes(item.mix_value)) {
        item.active = true
        selectedIds.push(item.mix_value)
      }
    })
    const selectedNum = selectedIds.length
    let label = selectedNum ? `${attr.name}(${selectedNum})` : attr.name
    if (filterBarType == FILTER_BAR_MAP.CLOUD_TAGS) { 
      const config = (filterBar?.filterConfig?.tagCloudSlotConfig || []).find(item => item.tagId == attr.id)
      const labelConfig = config?.displayContent?.labelLang || config?.tagName  || ''
      labelConfig && (label = selectedNum ? `${labelConfig}(${selectedNum})` : labelConfig)
    }

    return {
      ...attr,
      value: attr.id,
      label,
      selectedIds,
      selectedNum,
      active: selectedNum > 0,
      tagItemType: TAG_ITEM_TYPE.ATTR
    }
  })
}

export function handleAttrParams({ data, index, clickType, curSelectedItem, operation, fhContext, googleContext, isCccxFilterConfig, sourceFrom }) {
  let selectedAttrsWithHotMark = [] // 带有hot标识符
  const attrIds = []
  const attrValues = []
  const tspIds = []
  data.forEach(item => {
    if (item.nodeType == 7) {
      tspIds.push(`${item.id}_${item.mix_value}`)
      return
    }
    const attrs = item.mix_value.split('-')
    selectedAttrsWithHotMark = selectedAttrsWithHotMark.concat(attrs)
    attrIds.push(item.mix_value)
    attrValues.push(item.label.replace('-', '')) // one-size 等特殊情况去掉中横线
  })

  // 埋点
  if (operation != 'reset') {
    analysis.clickAttr({
      selectedAttrs: selectedAttrsWithHotMark.join('-'),
      index,
      clickType,
      curSelectedItem,
      facet: fhContext?.[`attr${curSelectedItem.id}`],
      gfilter: googleContext?.gfilter,
    })
  }
  const params = { 
    attr_ids: attrIds.join('-'), 
    attr_values: attrValues.join('-'),
    ...getSourceInsideFilterOrCloudTagPanel({ sourceFrom, active: curSelectedItem.active })
  }
  isCccxFilterConfig && (params.tsp_ids = tspIds.join(','))
  
  return params
}
